import React from "react"

const FilterForm = ({ setFilterFor, setFilterName }) => {
  const categories = [
    { title: "Traditional Plants", value: "traditionalPlants" },
    { title: "Children", value: "children" },
    { title: "Adults", value: "adults" },
    { title: "Elders", value: "elders" },
    { title: "Diet & Nutrition", value: "dietNutrition" },
  ]

  const updateOption = event => {
    if (event.target.value) {
      const data = JSON.parse(event.target.value)
      setFilterName(data.title)
      setFilterFor(data.value)
    } else {
      setFilterName(null)
      setFilterFor(null)
    }
  }

  return (
    <form className="ml-4 md:ml-0  md:mt-3 " id="filterForm">
      <select
        name="tags"
        id="tags"
        onChange={e => updateOption(e)}
        className="bg-white pl-1 pt-2 pb-1 "
      >
        <option selected name="none" id="none" value="">
          -- Show All --
        </option>
        {categories.map((category, index) => {
          return (
            <option
              value={JSON.stringify({
                title: category.title,
                value: category.value,
              })}
              name={category.value}
              id={category.value}
              key={`${category.value}-${index}`}
            >
              {category.title}
            </option>
          )
        })}
      </select>
    </form>
  )
}

export default FilterForm
