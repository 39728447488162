import React from "react"
import { BiGlobe } from "react-icons/bi"
import { GrMail } from "react-icons/gr"
import { MdPhone } from "react-icons/md"
import { camelCaseToNormal } from "../../helperFunctions"

import BlockContentPrimary from "../blockContentPrimary"

const DetailContainer = ({ children, icon = null }) => (
  <p className="flex  my-2">
    <span className="mr-2 flex items-center">{icon}</span>
    {children}
  </p>
)

const DirectoryItem = ({ resource }) => {
  return (
    <div className=" shadow p-2">
      <h2>{resource.title}</h2>
      <div>
        {resource._rawDetails && (
          <BlockContentPrimary blockData={resource._rawDetails} />
        )}

        {resource?.contactInfo?.url && (
          <DetailContainer icon={<BiGlobe />}>
            <a
              href={resource?.contactInfo?.url}
              target="_blank"
              rel="noopener noreferrer"
              className="underline hover:font-semibold visited:text-purple-900"
            >
              {resource?.contactInfo?.url}
            </a>
          </DetailContainer>
        )}
        {resource?.contactInfo?.phone && (
          <DetailContainer icon={<MdPhone />}>
            {resource?.contactInfo?.phone}
          </DetailContainer>
        )}

        {resource?.contactInfo?.email && (
          <DetailContainer icon={<GrMail />}>
            {resource?.contactInfo?.email}
          </DetailContainer>
        )}
        <div className="">
          <span className="italic">{`Categories: `}</span>
          {resource?.tags.length ? (
            <>
              {resource?.tags.map((tag, index) => (
                <span>
                  {camelCaseToNormal(tag)}
                  {resource?.tags?.length > 0 &&
                  index < resource.tags.length - 1
                    ? `, `
                    : null}
                </span>
              ))}
            </>
          ) : (
            "n/a"
          )}
        </div>
      </div>
    </div>
  )
}

export default DirectoryItem
