import React from "react"
import SectionTitle from "../SectionTitle"

const INPUT = ({
  name,
  id,
  type,
  placeholder,
  required = false,
  className,
}) => (
  <input
    name={name}
    id={id}
    placeholder={placeholder}
    type={type}
    required={required}
    className={` bg-gray-light w-full placeholder-gray-400 px-2 pt-1 md:mt-2 ${className}`}
  />
)

const LABEL = ({ htmlFor, children, className = "", required = false }) => (
  <label htmlFor={htmlFor} className={`md:text-right mt-2 pt-1 ${className}`}>
    {children}
    {required ? "*" : null}
  </label>
)

const CareDirectoryForm = () => {
  return (
    <>
      <form
        name="careDirectory"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success"
      >
        {/* If this hidden input isn't included, the form won't get to where it needs to go */}
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        {/* ref: https://cobwwweb.com/how-to-use-netlify-forms-with-gatsby || https://stackoverflow.com/questions/61318577/netify-gatsby-form-submission-not-working-with-custom-success-component*/}
        {/* Don't forget this field - won't work without it! */}
        {/* DOCS: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators */}
        {/* Value MUST BE THE NAME OF THE FORM */}
        <input type="hidden" name="form-name" value="careDirectory" />
        {/* HP */}
        <div className="hidden">
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
          <input name="bot-field" />
        </div>
        {/* End HP */}
        <div className="grid gap-x-4 md:grid-cols-careForm w-full px-10 ">
          <LABEL htmlFor="form__name" required>
            Name:
          </LABEL>
          <INPUT
            name="name"
            id="form__name"
            type="text"
            placeholder="Contact Name"
            required
          />
          <LABEL htmlFor="form__organization">Organization:</LABEL>
          <INPUT
            name="organization"
            id="form__organization"
            type="text"
            placeholder="Example Organization"
          />

          <LABEL htmlFor="form__email" required>
            Email:
          </LABEL>
          <INPUT
            name="email"
            id="form__email"
            type="email"
            placeholder="example@email.com"
            required
          />

          <LABEL htmlFor="Phone">Phone:</LABEL>
          <INPUT
            name="phone"
            id="form__phone"
            type="phone"
            placeholder="(555) 555-5555"
          />

          <LABEL htmlFor="form__details" required>
            Details:
          </LABEL>

          <textarea
            className="bg-gray-light w-full placeholder-gray-400 px-2 pt-1 mt-2"
            name="message"
            id="form__details"
            placeholder="Add details here"
            rows="5"
            required
          />

          {/* <p className="italic text-gray-400 mt-3">
          * Required fields must be filled before sending.
        </p> */}
          <div></div>
          <button
            type="submit"
            className=" px-8 py-1 border-2 border-quaternary  uppercase font-bold tracking-wider text-2xl mt-10  justify-self-end"
          >
            Submit
          </button>
          <div className=" justify-self-end col-span-full mt-3 italic">
            * Required fields
          </div>
        </div>
      </form>
    </>
  )
}

export default CareDirectoryForm
