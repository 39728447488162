import React, { useState } from "react"
import { graphql } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import PageSection from "../components/global/PageSection"
import DirectoryItem from "../components/careDirectory/DirectoryItem"
import FilterForm from "../components/careDirectory/FilterForm"
import ClearForm from "../components/careDirectory/ClearForm"
import CareDirectoryForm from "../components/careDirectory/CareDirectoryForm"
import SectionTitle from "../components/SectionTitle"
import { MdClear } from "react-icons/md"

const CareDirectory = props => {
  const [filterFor, setFilterFor] = useState(null)
  // Name for the "filtering for" statement
  const [filterName, setFilterName] = useState(null)
  const { sanityCareDirectory, heroDefault } = props.data
  const fullResourcesList = sanityCareDirectory.resources

  // Filter resources list for the stateful value of "resources to display"
  let resourcesToDisplay = filterFor
    ? fullResourcesList.filter(resource => resource.tags.includes(filterFor))
    : fullResourcesList

  const hero = sanityCareDirectory?.heroImage
    ? sanityCareDirectory.heroImage.asset
    : heroDefault.childImageSharp

  // const displayResources = resourcesToDisplay.slice(0, listLength)

  // resets state and form
  const handleFilterReset = () => {
    document.getElementById("filterForm").reset()
    setFilterName(null)
    setFilterFor(null)
  }

  return (
    <Layout>
      <SEO title={sanityCareDirectory.title} />
      <Hero englishTitle={sanityCareDirectory.title} heroImage={hero} />
      {/* Sections adapt layout to small vs medium + screen sizes */}
      <div className=" flex flex-col-reverse md:grid md:grid-cols-careDirectory text-primary-dark">
        {/* Directory Listing */}
        <section className=" flex flex-col items-center justify-center my-10">
          <div
            className={`${
              !filterName ? "invisible" : "visible"
            } hidden md:flex  mb-5 leading-normal text-base  items-end `}
          >
            <div className="">
              <span className="italic">Filter:</span>&nbsp;
              <span className="font-bold">{filterName}</span>
            </div>
            <button
              onClick={() => handleFilterReset()}
              className="ml-3 b-1 border border-primary-dark hover:bg-primary-dark hover:text-white rounded px-2 flex items-center"
            >
              <MdClear />
              <span className="ml-3">Clear</span>
            </button>
          </div>

          <div className="px-5  w-10/12 grid gap-y-12 ">
            {resourcesToDisplay.length > 0 ? (
              resourcesToDisplay.map((resource, index) => (
                <DirectoryItem
                  resource={resource}
                  key={`{${resource.title}-${index}}`}
                />
              ))
            ) : (
              <div className="font-heading text-2xl text-center mt-10">
                Sorry, no items to display.
              </div>
            )}
          </div>
          {/* Form to join the directory */}
          <div className="mt-20 max-w-3xl w-full">
            <SectionTitle className="mb-2" noLines>
              Want to join the directory?
            </SectionTitle>

            <CareDirectoryForm />
          </div>
        </section>
        {/* Filter Section */}
        <section className=" bg-gray-light p-5 flex items-end md:items-start md:flex-col  ">
          <h3 className="pb-0  leading-none">Filter</h3>
          <FilterForm
            filterFor={filterFor}
            setFilterFor={setFilterFor}
            filtername={filterName}
            setFilterName={setFilterName}
            fullResourcesList={fullResourcesList}
          />
          {filterFor && (
            <button
              onClick={() => handleFilterReset()}
              className="md:hidden ml-3 border border-primary-dark hover:bg-primary-dark hover:text-white rounded px-2 py-1 flex items-center self-center"
              title="Clear filter"
            >
              <MdClear />
            </button>
          )}
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CareDirectoryQuery {
    sanityCareDirectory {
      title
      heroImage {
        asset {
          fluid {
            base64
            srcWebp
            srcSetWebp
          }
        }
      }
      resources {
        title
        tags
        contactInfo {
          email
          phone
          url
        }
        _rawDetails(resolveReferences: { maxDepth: 10 })
      }
    }
    heroDefault: file(relativePath: { eq: "forest-lake.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1000, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CareDirectory
